import { Alert, AlertIcon } from "@chakra-ui/alert";

const style = {
  borderRadius: "sm",
  flex: "1",
  fontSize: "sm",
  padding: "2",
  width: "100%",
};

export const Warning = ({ children, ...props }) => {
  return (
    <Alert status="warning" {...style} variant="left-accent" {...props}>
      <AlertIcon width="4" />
      {children}
    </Alert>
  );
};

export const Error = ({ children }) => {
  return (
    <Alert status="error" {...style} variant="left-accent">
      <AlertIcon width="4" />
      {children}
    </Alert>
  );
};
