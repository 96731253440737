import useBackend from "./useBackend";

const useImageData = (imageId, options) =>
  useBackend(imageId ? `/images/${imageId}/image-data` : null, {
    options: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      focusThrottleInterval: 1000 * 60,
      ...options,
    },
  });

export default useImageData;
