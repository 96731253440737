import { DeleteIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";

export const DeleteButton = ({ onClick }) => (
  <Button
    colorScheme="red"
    variant="outline"
    size="xs"
    leftIcon={<DeleteIcon />}
    onClick={onClick}
    marginLeft="2"
  >
    Delete Inspection
  </Button>
);
