import { Flex, Box, IconButton } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useUrlState } from "../state/useUrlState";

export const PageButtons = ({ isDisabledNext }) => {
  const { data: page } = useUrlState("page", 0);
  const pageInt = parseInt(page);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  return (
    <Flex alignItems="center" justifyContent="center" key={location.search}>
      <IconButton
        icon={<ChevronLeftIcon />}
        onClick={() => navigateToNewPage(history, queryParams, pageInt - 1)}
        marginRight="5"
        isDisabled={pageInt < 1}
      >
        Previous
      </IconButton>
      <Box>{pageInt + 1}</Box>
      <IconButton
        marginLeft="5"
        icon={<ChevronRightIcon />}
        onClick={() => navigateToNewPage(history, queryParams, pageInt + 1)}
        isDisabled={isDisabledNext}
      >
        Next
      </IconButton>
    </Flex>
  );
};

const navigateToNewPage = (history, queryParams, newPage) => {
  const newParams = new URLSearchParams({
    ...Object.fromEntries(queryParams),
    page: newPage,
  });
  history.push({
    pathname: window.location.pathname,
    search: `?${newParams.toString().replace("%2C", ",")}`, // replace %2C with , to handle array of strings
  });
};
