import { Box } from "@chakra-ui/react";

export const FilterWrapper = ({ children, ...props }) => (
  <Box
    marginBottom="5"
    rounded="md"
    padding="5"
    border="1px"
    borderColor="gray.300"
    width="full"
    {...props}
  >
    {children}
  </Box>
);
