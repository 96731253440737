import {
  AspectRatio,
  Button,
  Center,
  Checkbox,
  Container,
  Heading,
  HStack,
  Link,
  Select,
  Spinner,
  Tag,
  TagLabel,
  Textarea,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { Controlled as ControlledZoom } from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { NearbyInspections } from "../../components/NearbyInspections.jsx";
import useImage from "../../backend/hooks/useImage.js";
import useImageData from "../../backend/hooks/useImageData.js";
import { HTTPError } from "../../components/HTTPError/index.jsx";

export default function Inspection(props) {
  const imageId = props.match.params.id;
  const [isZoomed, setIsZoomed] = useState(false);
  const history = useHistory();

  const image = useImage(imageId);
  const imageData = useImageData(imageId);

  const goToDeleted = (measurementName, frame_index, camera_index) => {
    const path = `/inspections/deleted?measurementName=${measurementName}&frameIndex=${frame_index}&cameraIndex=${camera_index}`;
    history.push(path);
  };

  const updateInspection = (changes) => {
    image.updateInspection({ ...image.data?.inspection, ...changes });
  };

  const handleActionTypeChange = (event) => {
    updateInspection({ action_type: event.target.value });
  };
  const handleOfficialCommentChange = (event) => {
    updateInspection({ official_comment: event.target.value });
  };
  const handleChangeOfficial = () => {
    updateInspection({ official: !image.data.inspection.official });
  };
  const handleInternalCommentChange = (event) => {
    updateInspection({ internal_comment: event.target.value });
  };

  const handleZoomChange = useCallback((shouldZoom) => {
    setIsZoomed(shouldZoom);
  }, []);

  if (image.error || imageData.error) {
    return (
      <HTTPError
        error={image.error || imageData.error}
        description="Could not load inspection"
      />
    );
  }

  if (image.isLoading || imageData.isLoading) {
    return (
      <Container maxW="container.lg">
        <Center height="100px">
          <Spinner />
        </Center>
      </Container>
    );
  }

  return (
    <Container maxW="container.lg">
      <VStack marginBottom="20px">
        <Link
          as={RouterLink}
          to={`/images/${image.data.measurement_name}/${image.data.frame_index}/${image.data.camera_index}`}
        >
          <Heading size="lg" marginTop="20px">
            <HStack>
              <Tooltip label="measurement_name">
                {image.data.measurement_name}
              </Tooltip>
              <span>/</span>
              <Tooltip label="frame_index">
                {image.data.frame_index.toString()}
              </Tooltip>
              <span>/</span>
              <Tooltip label="camera_index">
                {image.data.camera_index.toString()}
              </Tooltip>
            </HStack>
          </Heading>
        </Link>
        <Heading size="sm" style={{ marginTop: "0px" }}>
          <HStack>
            <Tooltip label="track_section">
              {image.data?.closest_position?.track_section}
            </Tooltip>
            <span>/</span>
            <Tooltip label="kilometer">
              {image.data?.closest_position?.kilometer.toString()}
            </Tooltip>
            <span>/</span>
            <Tooltip label="meter">
              {image.data?.closest_position?.meter.toString()}
            </Tooltip>
            <span>/</span>
            <Tooltip label="track_lane">
              {image.data?.closest_position?.track_lane}
            </Tooltip>
          </HStack>
        </Heading>
        <AspectRatio minWidth="100%" ratio={3.3}>
          <ControlledZoom
            overlayBgColorEnd="rgba(30, 30, 30, 0.95)"
            style={{ background: "lightblue" }}
            isZoomed={isZoomed}
            onZoomChange={handleZoomChange}
          >
            <img
              onClick={() => setIsZoomed(true)}
              src={imageData.data}
              style={{ objectFit: "fill", height: "100%" }}
              alt="damage"
            />
          </ControlledZoom>
        </AspectRatio>
        <HStack width="100%">
          {image.data?.annotations.map((annotation) => (
            <Tag
              size="sm"
              key={`${annotation.id}`}
              borderRadius="full"
              variant="solid"
            >
              <TagLabel>{annotation.type}</TagLabel>
            </Tag>
          ))}
        </HStack>
        <NearbyInspections
          status="warning"
          filter={(otherInspection) => otherInspection.id !== imageId}
          imageId={image.data.id}
        />
        <VStack width="100%">
          <div style={{ width: "100%" }}>
            <Select
              value={image.data?.inspection?.action_type || ""}
              onChange={handleActionTypeChange}
              placeholder="Select an action"
              borderColor="black"
            >
              <option value="immediate_inspection">
                1. Immediate inspection
              </option>
              <option value="inspection_within_three_weeks">
                2. Inspection within three weeks
              </option>
              <option value="inspection_within_one_year">
                3. Inspection within one year
              </option>
              <option value="no_inspection">4. No inspection</option>
            </Select>
          </div>
          <Textarea
            value={image.data?.inspection?.official_comment || ""}
            onChange={handleOfficialCommentChange}
            height="100%"
            borderColor="back"
            placeholder="Comment"
          />
          <div style={{ width: "100%" }}>
            <Checkbox
              isChecked={image.data?.inspection?.official}
              onChange={handleChangeOfficial}
            >
              Mark for official review
            </Checkbox>
          </div>
          <Textarea
            value={image.data?.inspection?.internal_comment || ""}
            onChange={handleInternalCommentChange}
            height="100%"
            borderColor="back"
            placeholder="Internal comment"
          />
          <Button
            colorScheme="red"
            onClick={() =>
              image
                .deleteInspection()
                .then((_) =>
                  goToDeleted(
                    image.data.measurement_name,
                    image.data.frame_index,
                    image.data.camera_index
                  )
                )
            }
          >
            Delete inspection
          </Button>
        </VStack>
      </VStack>
    </Container>
  );
}
