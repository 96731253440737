import { ReactKeycloakProvider } from "@react-keycloak/web";
import { useConfig } from "../state/ConfigProvider";
import Keycloak from "keycloak-js";

export const AuthenticationProvider = ({ children }) => {
  const { auth } = useConfig();
  const authClient = new Keycloak(auth);

  return (
    <ReactKeycloakProvider
      authClient={authClient}
      initOptions={{
        flow: "implicit",
        onLoad: "login-required",
      }}
    >
      {children}
    </ReactKeycloakProvider>
  );
};
