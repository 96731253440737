import { Container, Center, Spinner } from "@chakra-ui/react";

export const Loader = ({ isLoading = true }) => {
  if (isLoading) {
    return (
      <Container maxW="container.md">
        <Center height="100px">
          <Spinner />
        </Center>
      </Container>
    );
  }

  return null;
};
