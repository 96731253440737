import { useReducer } from "react";
import { SWRConfig } from "swr";
import { AppContext } from "./AppContext";
import { useConfig } from "./ConfigProvider";
import { initialState } from "./initialState";
import { reducer } from "./reducer";
import { swrLogger } from "./swrLogger";

export const State = ({ children }) => {
  const config = useConfig();
  const [state, dispatch] = useReducer(reducer(config), initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <SWRConfig
        value={{ provider: () => new Map(), use: [swrLogger(config)] }}
      >
        {children}
      </SWRConfig>
    </AppContext.Provider>
  );
};
