import { Checkbox, CheckboxGroup } from "@chakra-ui/checkbox";
import { VStack } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/react";
import { statusNames } from "../../utils/positionFileStatuses";
import { withEvent } from "../../utils/onEvent";
import { FilterWrapper } from "../../components/filters/FilterWrapper";

export const StatusFilters = ({ statusFilters, setStatusFilters }) => {
  return (
    <FilterWrapper>
      <Heading textAlign="left" size="xs" marginBottom="2">
        Filter files by status
      </Heading>

      <CheckboxGroup
        colorScheme="green"
        defaultValue={[]}
        value={statusFilters}
        onChange={withEvent(setStatusFilters)}
        flex="1"
        size="sm"
      >
        <VStack align="left">
          {Object.keys(statusNames).map((action) => (
            <Checkbox value={action} key={action}>
              {statusNames[action]}
            </Checkbox>
          ))}
        </VStack>
      </CheckboxGroup>
    </FilterWrapper>
  );
};
