import useDeleteUnnanotated from "./useDeleteUnannotated";
import { DeleteButton } from "../../components/DeleteButton";

export const DeleteUnannotatedButton = ({ measurementName }) => {
  const deleteUnnanotated = useDeleteUnnanotated();

  return (
    <DeleteButton
      title="Delete uploaded images"
      onClick={() => {
        deleteUnnanotated(measurementName);
      }}
      alertHeader={`Delete all unannotated images for ${measurementName}`}
      alertBody="This will delete both damage and joint images. Are you sure?"
    />
  );
};
