import { Tbody, Td, Th, Thead, Tr, Tooltip } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { DeleteUnannotatedButton } from "./DeleteUnannotatedButton";
import { DAMAGE } from "../Annotate/annotationTypes";
import { TableWrapper } from "../../components/Table/TableWrapper";
import { sortMeasurements } from "./sortMeasurements";
import { MeasurementNameCell } from "./MeasurementNameCell";

export const DamagesTable = ({ measurements }) => {
  return (
    <TableWrapper>
      <Thead>
        <Tr>
          <Th>Measurement</Th>
          <Th isNumeric>Total&nbsp;images</Th>
          <Th isNumeric>Uploaded&nbsp;images</Th>
          <Th isNumeric>
            Unannotated&nbsp;(
            {measurements.reduce(
              (value, measurement) => value + measurement.unannotated_damages,
              0
            )}
            )
          </Th>
          <Th isNumeric>Verified&nbsp;damages</Th>
          <Th isNumeric>Verified&nbsp;not&nbsp;damages</Th>
        </Tr>
      </Thead>
      <Tbody>
        {sortMeasurements(measurements).map((measurement) => (
          <Tr key={measurement.measurement_name}>
            <MeasurementNameCell
              measurement={measurement}
              targetType={DAMAGE}
            />
            <Td isNumeric>
              {measurement.images
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Td>
            <Td isNumeric>
              {measurement.damage_images} (
              {((measurement.damage_images / measurement.images) * 100).toFixed(
                2
              )}
              %)
            </Td>
            <UnannotatedCell measurement={measurement} />
            <VerifiedDamagesCell measurement={measurement} />
            <Td isNumeric>{measurement.verified_not_damages}</Td>
          </Tr>
        ))}
      </Tbody>
    </TableWrapper>
  );
};

const UnannotatedCell = ({ measurement }) => (
  <Td isNumeric>
    {measurement.annotated_damages >= 1 && (
      <Tooltip
        label={
          measurement.annotated_damages >= 2
            ? `This measurement has ${measurement.annotated_damages} annotations`
            : `This measurement has ${measurement.annotated_damages} annotation`
        }
      >
        <InfoIcon color="green.300" marginTop="-2px" />
      </Tooltip>
    )}{" "}
    {measurement.unannotated_damages}{" "}
    <DeleteUnannotatedButton measurementName={measurement.measurement_name} />
  </Td>
);

const VerifiedDamagesCell = ({ measurement }) => (
  <Td isNumeric>
    {measurement.inspections >= 1 && (
      <Tooltip
        label={
          measurement.inspections >= 2
            ? `This measurement has ${measurement.inspections} inspections`
            : `This measurement has ${measurement.inspections} inspections`
        }
      >
        <InfoIcon color="yellow.400" marginTop="-2px" />
      </Tooltip>
    )}{" "}
    {measurement.broken_strands +
      measurement.tape_joints +
      measurement.faulty_joints}
  </Td>
);
