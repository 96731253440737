import { useState } from "react";
import { Checkbox } from "@chakra-ui/checkbox";
import {
  VStack,
  Box,
  Select,
  Spacer,
  Heading,
  HStack,
  Button,
} from "@chakra-ui/react";
import { Layout } from "../../components/Layout";
import { useUrlState } from "../../state/useUrlState";
import { DAMAGE, SOFT_WIRE_DAMAGE, JOINT } from "../Annotate/annotationTypes";
import useMeasurements from "../../backend/hooks/useMeasurements";
import { DamagesTable } from "./DamagesTable";
import { JointsTable } from "./JointsTable";
import { SoftWireDamagesTable } from "./SoftWireDamagesTable";
import { Warning } from "../../components/Warnings";
import { withTargetValue } from "../../utils/onEvent";
import { Loader } from "../../components/Loader";
import { HTTPError } from "../../components/HTTPError";
import { useLocation } from "react-router-dom";

const monthNames = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

const allowedYears = [];
for (let i = 2018; i < new Date().getFullYear() + 1; i++) {
  allowedYears.push(i);
}

const Sidebar = ({
  unpredictedImages,
  targetType,
  setTargetType,
  onlyUnannotated,
  setOnlyUnannotated,
  selectedDates,
  setSelectedDates,
}) => {
  const { data: fromMonth, set: setFromMonth } = useUrlState(
    "fromMonth",
    selectedDates.from.getMonth()
  );
  const { data: fromYear, set: setFromYear } = useUrlState(
    "fromYear",
    selectedDates.from.getFullYear()
  );
  const { data: toMonth, set: setToMonth } = useUrlState(
    "toMonth",
    selectedDates.to.getMonth()
  );
  const { data: toYear, set: setToYear } = useUrlState(
    "toYear",
    selectedDates.to.getFullYear()
  );

  return (
    <>
      <Select
        variant="filled"
        value={targetType}
        onChange={withTargetValue(setTargetType)}
      >
        <option value={DAMAGE}>Damages</option>
        <option value={SOFT_WIRE_DAMAGE}>Soft Wire Damages</option>
        <option value={JOINT}>Joints</option>
      </Select>
      &nbsp;
      <Checkbox
        isChecked={onlyUnannotated === "yes"}
        onChange={() => {
          setOnlyUnannotated(onlyUnannotated === "yes" ? "no" : "yes");
        }}
      >
        Show only unannotated
      </Checkbox>
      <Box
        width="100%"
        align="center"
        border="1px solid"
        borderColor="gray.300"
        borderRadius="md"
        padding="20px"
        marginTop="20px"
      >
        <Heading size="sm" marginBottom={2}>
          From
        </Heading>
        <HStack spacing={4} width="100%">
          <SelectMonth value={fromMonth} setValue={setFromMonth} />
          <SelectYear value={fromYear} setValue={setFromYear} />
        </HStack>
        <Heading size="sm" marginBottom={2} marginTop={4}>
          To
        </Heading>
        <HStack spacing={4} width="100%">
          <SelectMonth value={toMonth} setValue={setToMonth} />
          <SelectYear value={toYear} setValue={setToYear} />
        </HStack>
        <Button
          onClick={() =>
            setSelectedDates({
              from: new Date(fromYear, fromMonth, 1),
              to: new Date(toYear, toMonth, 1),
            })
          }
          marginTop={4}
        >
          Filter measurements
        </Button>
      </Box>
      <Spacer marginBottom="5" />
      {unpredictedImages >= 1 && (
        <Warning>
          There are {unpredictedImages} images without predictions.
        </Warning>
      )}
    </>
  );
};

const SelectMonth = ({ value, setValue }) => (
  <Select
    value={value}
    onChange={(event) => handleSelectChange(event, setValue)}
  >
    {Object.keys(monthNames).map((month) => (
      <option value={month} key={`${month}`}>
        {monthNames[month]}
      </option>
    ))}
  </Select>
);

const SelectYear = ({ value, setValue }) => (
  <Select
    value={value}
    onChange={(event) => handleSelectChange(event, setValue)}
  >
    {allowedYears.map((year) => (
      <option value={year} key={`${year}`}>
        {year}
      </option>
    ))}
  </Select>
);

const handleSelectChange = (event, setter) => {
  setter(event.target.value);
};

const renderTable = (measurements, targetType) => {
  switch (targetType) {
    case DAMAGE: {
      return <DamagesTable measurements={measurements} />;
    }
    case SOFT_WIRE_DAMAGE: {
      return <SoftWireDamagesTable measurements={measurements} />;
    }
    case JOINT: {
      return <JointsTable measurements={measurements} />;
    }
    default: {
      throw new Error("not implemented");
    }
  }
};

export const Measurements = () => {
  const today = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(today.getMonth() - 1);

  const location = useLocation();
  const queryParams = Object.fromEntries(new URLSearchParams(location.search));

  const [selectedDates, setSelectedDates] = useState({
    from: new Date(
      queryParams.fromYear || oneMonthAgo.getFullYear(),
      queryParams.fromMonth || oneMonthAgo.getMonth(),
      1
    ),
    to: new Date(
      queryParams.toYear || today.getFullYear(),
      queryParams.toMonth || today.getMonth(),
      1
    ),
  });
  const measurements = useMeasurements(
    selectedDates.from,
    new Date(
      new Date(selectedDates.to).setDate(selectedDates.to.getDate() + 31)
    )
  );
  const { data: onlyUnannotated, set: setOnlyUnannotated } = useUrlState(
    "onlyUnannotated",
    "no"
  );

  const { data: targetType, set: setTargetType } = useUrlState(
    "targetType",
    DAMAGE
  );

  if (measurements.isLoading) {
    return <Loader isLoading />;
  }

  if (measurements.error) {
    return (
      <Layout>
        <HTTPError
          error={measurements.error}
          description="Failed to load measurements"
        />
      </Layout>
    );
  }

  const unpredictedImages = measurements.data.reduce(
    (value, measurement) => value + measurement.unpredicted,
    0
  );

  let shown_measurements = measurements.data;

  if (onlyUnannotated === "yes") {
    shown_measurements = shown_measurements.filter((measurement) =>
      targetType === DAMAGE
        ? measurement.unannotated_damages > 0
        : targetType === SOFT_WIRE_DAMAGE
        ? measurement.unannotated_soft_wire_damages > 0
        : targetType === JOINT
        ? measurement.unannotated_joints > 0
        : true
    );
  }

  return (
    <Layout
      sidebar={
        <Sidebar
          unpredictedImages={unpredictedImages}
          targetType={targetType}
          setTargetType={setTargetType}
          onlyUnannotated={onlyUnannotated}
          setOnlyUnannotated={setOnlyUnannotated}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
        />
      }
    >
      <Box width="100%">
        <VStack>
          {renderTable(shown_measurements, targetType, unpredictedImages)}
        </VStack>
      </Box>
    </Layout>
  );
};
