import { Button } from "@chakra-ui/react";
import "react-medium-image-zoom/dist/styles.css";
import { useState } from "react";
import { useAuthorizedFetcher } from "../../backend/hooks/useAuthorizedFetcher.js";

export const ExportButtonMonthDeprecated = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const authorizedFetcher = useAuthorizedFetcher();

  const download = () => {
    setIsDownloading(true);
    authorizedFetcher("/optram-export/zip/deprecated?days=30").then((blob) => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = "optram-export-v0.2.0.zip";
      document.body.appendChild(a);
      a.click();
      a.remove();
      setIsDownloading(false);
    });
  };

  if (isDownloading) {
    return (
      <Button isLoading colorScheme="blue" width="100%" marginTop="18px">
        Downloading
      </Button>
    );
  }

  return (
    <Button
      colorScheme="blue"
      variant="outline"
      onClick={download}
      width="100%"
      marginTop="18px"
      style={{
        whiteSpace: "normal",
        wordWrap: "break-word",
      }}
      height="55px"
    >
      Download damages over 30 days v0.2.0 (deprecated)
    </Button>
  );
};
