import { Tbody, Thead, Tr, Th } from "@chakra-ui/react";
import useBackend from "../../backend/hooks/useBackend";

import { TableWrapper } from "../../components/Table/TableWrapper";
import { PositionFileRow } from "./PositionFileRow";

const renderFiles = (positionFiles) => {
  if ([undefined, null].includes(positionFiles)) {
    return null;
  }

  return positionFiles
    .sort((positionFileA, positionFileB) =>
      positionFileA.measurement_name > positionFileB.measurement_name ? -1 : 1
    )
    .map((positionFile) => (
      <PositionFileRow key={positionFile.measurement_name} {...positionFile} />
    ));
};

export const FileList = ({ statusFilters }) => {
  const { data: positionFiles } = useBackend("/position-files");

  return (
    <TableWrapper size="md">
      <Thead>
        <Tr>
          <Th>Measurement Name</Th>
          <Th textAlign="center">kmm2</Th>
          <Th textAlign="center">hdr</Th>
          <Th>Status</Th>
          <Th>Modified</Th>
          <Th>Actions</Th>
        </Tr>
      </Thead>
      <Tbody>
        {renderFiles(
          positionFiles?.filter((positionFile) =>
            statusFilters.includes(positionFile.status)
          )
        )}
      </Tbody>
    </TableWrapper>
  );
};
