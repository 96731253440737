import { useAuthorizedFetcher } from "./useAuthorizedFetcher";
import useBackend from "./useBackend";
import useMatchMutate from "./useMatchMutate";

const useImage = (imageId, options) => {
  const matchMutate = useMatchMutate();
  const authorizedFetcher = useAuthorizedFetcher();
  const image = useBackend(imageId ? `/images/${imageId}` : null, {
    options: {
      ...options,
      onMutate: (imageData) => matchMutate("/images?", imageData, false),
    },
  });

  const createAnnotation = (annotationType) => {
    if (!image.isLoading) {
      return authorizedFetcher(`/images/${imageId}/annotations`, {
        method: "POST",
        body: { type: annotationType },
      })
        .then((annotatedImage) => image.mutate(annotatedImage, false))
        .catch(console.error);
    } else {
      return Promise.resolve(image);
    }
  };

  const deleteAnnotation = (annotationId) => {
    image.mutate(
      {
        ...image.data,
        annotations: image.data.annotations.filter(
          (annotation) => annotation.id !== annotationId
        ),
      },
      false
    );
    return image.mutate(
      authorizedFetcher(`/images/${imageId}/annotations/${annotationId}`, {
        method: "DELETE",
      }),
      false
    );
  };

  const requestInspection = (method) => (inspection) => {
    const newInspection = replaceEmpty(inspection);
    image.mutate({ ...image.data, inspection: newInspection }, false);
    return image.mutate(
      authorizedFetcher(`/images/${imageId}/inspection`, {
        method,
        body: newInspection,
      }),
      false
    );
  };
  const createInspection = requestInspection("POST");
  const updateInspection = requestInspection("PUT");

  const deleteInspection = () => {
    delete image.data.inspection;
    image.mutate(image.data, false);
    return image.mutate(
      authorizedFetcher(`/images/${imageId}/inspection`, {
        method: "DELETE",
      }),
      false
    );
  };

  return {
    ...image,
    createAnnotation,
    deleteAnnotation,
    createInspection,
    updateInspection,
    deleteInspection,
  };
};

const replaceEmpty = (object) =>
  Object.fromEntries(
    Object.entries(object).map(([key, value]) => [
      key,
      value === "" ? null : value,
    ])
  );

export default useImage;
