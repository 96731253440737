import { Alert, AlertIcon, Link, Button } from "@chakra-ui/react";
import React from "react";
import "react-medium-image-zoom/dist/styles.css";
import { useUrlState } from "../../state/useUrlState.js";
import { Link as RouterLink } from "react-router-dom";
import { Layout } from "../../components/Layout/index.jsx";

export default function DeletedInspection(props) {
  const { data: measurementName } = useUrlState("measurementName");
  const { data: frameIndex } = useUrlState("frameIndex");
  const { data: cameraIndex } = useUrlState("cameraIndex");

  const redirectUrl =
    measurementName && frameIndex && cameraIndex
      ? `/images/${measurementName}/${frameIndex}/${cameraIndex}`
      : undefined;

  return (
    <Layout>
      <Alert status="success" variant="left-accent" borderRadius="sm">
        <AlertIcon />
        Inspection successfully deleted
      </Alert>
      {redirectUrl && (
        <Link as={RouterLink} to={redirectUrl}>
          <Button>Go to image</Button>
        </Link>
      )}
    </Layout>
  );
}
