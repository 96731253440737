export const UNPROCESSED = "unprocessed";
export const QUEUED = "queued";
export const PROCESSING = "processing";
export const PROCESSED = "processed";
export const ERROR = "error";
export const MISSING = "missing";
export const IGNORED = "ignored";

export const statusNames = {
  [UNPROCESSED]: "Unprocessed",
  [QUEUED]: "Queued",
  [PROCESSING]: "Processing",
  [PROCESSED]: "Processed",
  [ERROR]: "Error",
  [MISSING]: "Missing",
  [IGNORED]: "Ignored",
};
