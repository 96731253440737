import { Link } from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import React from "react";

const LINK_COLOR = "green.500";
const LINK_HOVER_COLOR = "gray.900";

export const NavLink = ({ name, link }) => {
  const { pathname } = useLocation();
  const isSelected =
    (["/", "/damage-map"].includes(pathname) && name === "Damages") ||
    pathname === link;

  return (
    <Link
      as={RouterLink}
      to={link}
      color={isSelected ? LINK_COLOR : "gray.500"}
      fontWeight="bold"
      textTransform="uppercase"
      fontSize="xs"
      display="flex"
      alignItems="center"
      _focus={{ outline: "none" }}
      _hover={{
        fontWeight: "bold",
        borderColor: LINK_HOVER_COLOR,
        color: LINK_HOVER_COLOR,
      }}
      marginRight="5"
      borderBottom="2px solid"
      borderColor={isSelected ? LINK_COLOR : "transparent"}
      _last={{ marginRight: "0" }}
    >
      {name}
    </Link>
  );
};
