import { Link } from "@chakra-ui/react";

export const LinkButton = ({ children, ...props }) => (
  <Link
    fontSize="xs"
    borderRadius="md"
    border="1px solid"
    borderColor="gray.200"
    paddingLeft="2"
    paddingRight="2"
    display="flex"
    alignItems="center"
    justifyContent="center"
    textDecoration="none"
    _hover={{
      backgroundColor: "gray.100",
    }}
    {...props}
  >
    {children}
  </Link>
);
