import { useUrlState } from "./useUrlState.js";
import { isDefined } from "@nextml/lodestar";

export const useLimitAndOffset = () => {
  const { data: page } = useUrlState("page", 0);
  const parsedPage = isDefined(page) ? parseInt(page) : 0;
  const limit = 10;
  const offset = parsedPage * limit;
  return { limit, offset };
};
