import stateLogger from "./logger";

export const swrLogger =
  (config) => (useSWRNext) => (key, fetcher, SWRConfig) => {
    const { cache } = SWRConfig;
    const beforeUpdate = cache.get(key);
    const swr = useSWRNext(key, fetcher, SWRConfig);

    if (config?.logLevel === "DEBUG")
      stateLogger(beforeUpdate, { type: "useSWR", key }, swr?.data);

    return swr;
  };
