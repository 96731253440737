import useBackend from "./useBackend";

const useMeasurements = (fromDate, toDate) => {
  const query = {
    fromDatetime: fromDate.toISOString(),
    toDatetime: toDate.toISOString(),
  };

  return useBackend("/measurements", { query: query });
};

export default useMeasurements;
