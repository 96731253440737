const DEFAULT_FONT = "Fira Code";

const styling = Object.freeze({
  state: {
    color: "#f39c12",
    fontFamily: DEFAULT_FONT,
  },
  action: {
    color: "#2980b9",
    fontFamily: DEFAULT_FONT,
  },
  nextState: {
    color: "#27ae60",
    fontFamily: DEFAULT_FONT,
  },
});

const consoleStyling = ({ color, fontFamily }) => `
  color: ${color};
  font-weight: 900;
  font-family: ${fontFamily}, monospace;
  font-style: italic;
  font-size: 1rem;
`;

const stateLogger = (state, action, nextState) => {
  console.log(`%c-> ${action.type}: ${action.key}`, "font-weight: bold");
  console.log("%cPrevious state:", consoleStyling(styling.state));
  console.log(state);
  console.log();
  console.log("%cPayload:", consoleStyling(styling.action));
  console.log(action.payload);
  console.log();
  console.log("%cNext state:", consoleStyling(styling.nextState));
  console.log(nextState);
  console.log("%c--------------------------------------", "font-weight: bold");
};

export default stateLogger;
