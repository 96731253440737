import { WarningIcon } from "@chakra-ui/icons";
import { Link, Td, Tooltip } from "@chakra-ui/react";

export const MeasurementNameCell = ({ measurement, targetType }) => {
  return (
    <Td>
      <Link
        fontWeight="600"
        color="blue.400"
        href={`/images/${measurement.measurement_name}?targetType=${targetType}`}
      >
        {measurement.measurement_name}
      </Link>{" "}
      {!measurement.positions && (
        <Tooltip label="This measurement has no positions">
          <WarningIcon color="orange.500" marginTop="-2px" />
        </Tooltip>
      )}
    </Td>
  );
};
