import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from "@chakra-ui/react";

export const HTTPError = ({ error, description }) => {
  return (
    <Box width="full">
      <Popover>
        <PopoverTrigger>
          <Alert status="error" variant="left-accent" borderRadius="sm">
            <AlertIcon />
            <AlertTitle mr={2}>{description}</AlertTitle>
          </Alert>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>
            {error.status} - {error.statusText}
          </PopoverHeader>
          <PopoverBody>{error.url}</PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
