import { Textarea } from "@chakra-ui/react";

export const DamageComment = ({ ...props }) => (
  <Textarea
    height="100%"
    placeholder="Comment"
    resize="none"
    backgroundColor="gray.100"
    border="none"
    fontSize="sm"
    {...props}
  />
);
