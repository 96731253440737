import { useUrlState } from "../../state/useUrlState";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { withTargetValue } from "../../utils/onEvent";
import { SearchIcon } from "@chakra-ui/icons";

export const UrlStateInput = ({ urlStateKey, icon, size, ...props }) => {
  const { data, set } = useUrlState(urlStateKey);

  return (
    <InputGroup size={size}>
      {icon ? (
        <InputLeftElement
          pointerEvents="none"
          children={<SearchIcon color="gray.300" />}
        />
      ) : null}
      <Input onChange={withTargetValue(set)} value={data} {...props} />
    </InputGroup>
  );
};
