import { VStack, Box, Spacer, Checkbox, Badge, Flex } from "@chakra-ui/react";
import { NearbyInspections } from "../../components/NearbyInspections.jsx";
import { DamageComment } from "../../components/DamageComment.jsx";
import { InspectionImage } from "../../components/InspectionImage";
import useImage from "../../backend/hooks/useImage.js";
import { DamageFooter } from "../../components/Damage/DamageFooter.jsx";
import { DamageHeader } from "../../components/Damage/DamageHeader";
import { DamageWrapper } from "../../components/Damage/DamageWrapper.jsx";
import { SearchLink } from "../../components/Damage/buttons/Search.jsx";
import { DeleteButton } from "../../components/Damage/buttons/Delete.jsx";
import { AnnotationLink } from "../../components/Damage/buttons/Annotate.jsx";
import { DamageContent } from "../../components/Damage/DamageContent.jsx";
import "react-medium-image-zoom/dist/styles.css";

export const Damage = ({ initialImage, onChange, onDelete }) => {
  const image = useImage(initialImage.id, { fallbackData: initialImage });
  const inspection = image.data.inspection;

  const updateInspection = (changes) => {
    const newInspection = { ...inspection, ...changes };
    image.updateInspection(newInspection);
    onChange({
      ...image.data,
      inspection: newInspection,
    });
  };

  const deleteInspection = () => {
    image.deleteInspection();
    onDelete();
  };

  return (
    <DamageWrapper>
      <DamageHeader image={image.data} />
      <DamageContent>
        <InspectionImage image={image} />

        <Spacer />

        <VStack width="100%" marginLeft="3">
          <Checkbox
            isChecked={inspection?.official}
            onChange={(event) =>
              updateInspection({ official: event.target.checked })
            }
            size="sm"
          >
            Mark for official review
          </Checkbox>
          <DamageComment
            onChange={(event) =>
              updateInspection({ internal_comment: event.target.value })
            }
            value={inspection?.internal_comment || ""}
            placeholder="Internal Comment"
          />
        </VStack>
      </DamageContent>

      <Box marginLeft="3" marginRight="3" marginBottom="2">
        <NearbyInspections
          status="warning"
          imageId={image.data.id}
          filter={(otherImage) => otherImage.id !== image.data.id}
        />
      </Box>
      <DamageFooter inspection={inspection}>
        {inspection.fixed || (
          <Flex alignItems="center">
            <Badge colorScheme="orange">possibly fixed</Badge>
          </Flex>
        )}
        <AnnotationLink image={image} />
        <SearchLink image={image} />
        <DeleteButton onClick={deleteInspection} />

        <Spacer />
      </DamageFooter>
    </DamageWrapper>
  );
};
