import { AspectRatio } from "@chakra-ui/react";
import { Image } from "./Image";

export const KW6Image = ({ width, ...props }) => (
  <AspectRatio width={width} ratio={3.3}>
    <Image
      style={{
        // NOTE: objectFit="fill" is not working.
        objectFit: "fill",
      }}
      height="100%"
      {...props}
    />
  </AspectRatio>
);
