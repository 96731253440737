import { useSWRConfig } from "swr";

const useMatchMutate = () => {
  const { cache, mutate } = useSWRConfig();
  return (partialKey, dataModel, shouldRevalidate) => {
    const keys = [];

    for (const key of cache.keys()) {
      if (key.includes(partialKey)) {
        keys.push(key);
      }
    }

    const mutations = keys.map((key) =>
      mutate(
        key,
        Array.isArray(cache.get(key))
          ? cache
              .get(key)
              .map((oldDataModel) =>
                dataModel.id === oldDataModel.id ? dataModel : oldDataModel
              )
          : cache.get(key),
        shouldRevalidate
      )
    );

    return Promise.all(mutations);
  };
};

export default useMatchMutate;
