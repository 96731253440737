import { Tbody, Td, Th, Thead, Tr, Tooltip } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { DeleteUnannotatedButton } from "./DeleteUnannotatedButton";
import { JOINT } from "../Annotate/annotationTypes";
import { TableWrapper } from "../../components/Table/TableWrapper";
import { sortMeasurements } from "./sortMeasurements";
import { MeasurementNameCell } from "./MeasurementNameCell";

export const JointsTable = ({ measurements }) => {
  return (
    <TableWrapper>
      <Thead>
        <Tr>
          <Th>Measurement</Th>
          <Th isNumeric>Total&nbsp;images</Th>
          <Th isNumeric>Uploaded&nbsp;images</Th>
          <Th isNumeric>
            Unannotated&nbsp;(
            {measurements.reduce(
              (value, measurement) => value + measurement.unannotated_joints,
              0
            )}
            )
          </Th>
          <Th isNumeric>Verified&nbsp;joints</Th>
          <Th isNumeric>Verified&nbsp;not&nbsp;joints</Th>
        </Tr>
      </Thead>
      <Tbody>
        {sortMeasurements(measurements).map((measurement) => (
          <Tr key={measurement.measurement_name}>
            <MeasurementNameCell measurement={measurement} targetType={JOINT} />
            <Td isNumeric>
              {measurement.images
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Td>
            <Td isNumeric>
              {measurement.joint_images} (
              {((measurement.joint_images / measurement.images) * 100).toFixed(
                2
              )}
              %)
            </Td>
            <UnannotatedCell measurement={measurement} />
            <VerifiedJointsCell measurement={measurement} />
            <Td isNumeric>{measurement.verified_not_joints}</Td>
          </Tr>
        ))}
      </Tbody>
    </TableWrapper>
  );
};

const UnannotatedCell = ({ measurement }) => (
  <Td isNumeric>
    {measurement.annotated_joints >= 1 && (
      <Tooltip
        label={
          measurement.annotated_joints >= 2
            ? `This measurement has ${measurement.annotated_joints} annotations`
            : `This measurement has ${measurement.annotated_joints} annotation`
        }
      >
        <InfoIcon color="green.300" marginTop="-2px" />
      </Tooltip>
    )}{" "}
    {measurement.unannotated_joints}{" "}
    <DeleteUnannotatedButton measurementName={measurement.measurementName} />
  </Td>
);

const VerifiedJointsCell = ({ measurement }) => (
  <Td isNumeric>
    {measurement.inspections >= 1 && (
      <Tooltip
        label={
          measurement.inspections >= 2
            ? `This measurement has ${measurement.inspections} inspections`
            : `This measurement has ${measurement.inspections} inspections`
        }
      >
        <InfoIcon color="yellow.400" marginTop="-2px" />
      </Tooltip>
    )}{" "}
    {measurement.press_joints + measurement.twist_joints}{" "}
  </Td>
);
