import { useAuthorizedFetcher } from "../../backend/hooks/useAuthorizedFetcher";

export default function useDeleteUnnanotated() {
  const authorizedFetcher = useAuthorizedFetcher();

  if (!authorizedFetcher) {
    return undefined;
  }

  return (measurementName) => {
    authorizedFetcher(`/images/${measurementName}/requires-annotation`, {
      method: "DELETE",
    }).catch(console.error);
  };
}
