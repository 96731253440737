export const getMapView = (query, inspections) => {
  const params = new URLSearchParams(query);
  if (params.get("inspection")) {
    const index = inspections.findIndex(
      (inspection) => inspection.id === params.get("inspection")
    );

    if (index !== -1) {
      return {
        inspection: index,
        coordinates: inspections[index].coordinates,
        zoom: 12,
        setPin: false,
      };
    }
  } else if (params.get("longitude") && params.get("latitude")) {
    return {
      inspection: undefined,
      coordinates: [params.get("longitude"), params.get("latitude")],
      zoom: 12,
      setPin: true,
    };
  }

  return {
    inspection: undefined,
    coordinates: [15, 63],
    zoom: 4.6,
    setPin: false,
  };
};
