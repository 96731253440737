import { useKeycloak } from "@react-keycloak/web";

export const useAuthorizationToken = () => {
  const { keycloak } = useKeycloak();

  if (keycloak?.token) {
    return `Bearer ${keycloak.token}`;
  } else {
    return null;
  }
};
