import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Td,
  IconButton,
} from "@chakra-ui/react";
import { ViewOffIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { useAuthorizedFetcher } from "../../backend/hooks/useAuthorizedFetcher";
import useBackend from "../../backend/hooks/useBackend";
import { DeleteButton } from "../../components/DeleteButton";

export const ActionsCell = ({ measurementName }) => {
  const [deleteErrorResponse, setDeleteErrorResponse] = useState(undefined);
  return (
    <Td>
      <IgnoreMeasurementButton measurementName={measurementName} />
      <DeleteMeasurementButton
        measurementName={measurementName}
        setDeleteErrorResponse={setDeleteErrorResponse}
      />{" "}
      {deleteErrorResponse && <AlertForbidden response={deleteErrorResponse} />}
    </Td>
  );
};

const IgnoreMeasurementButton = ({ measurementName }) => {
  const authorizedFetcher = useAuthorizedFetcher();
  const { data: positionFiles, mutate: mutatePositionFiles } =
    useBackend("/position-files");
  return (
    <IconButton
      onClick={() => {
        authorizedFetcher(`/position-files/${measurementName}/toggle-ignore`, {
          method: "POST",
        })
          .then(() => mutatePositionFiles(positionFiles, true))
          .catch(console.error);
      }}
      icon={<ViewOffIcon />}
      colorScheme="blue"
      size="xs"
      cursor="pointer"
      marginLeft="2"
      title="Hide measurement"
    />
  );
};

const DeleteMeasurementButton = ({
  measurementName,
  setDeleteErrorResponse,
}) => {
  const { data: positionFiles, mutate: mutatePositionFiles } =
    useBackend("/position-files");
  const authorizedFetcher = useAuthorizedFetcher();

  if (!authorizedFetcher) {
    return null;
  }

  return (
    <DeleteButton
      title="Delete measurement"
      onClick={() => {
        authorizedFetcher(`/measurements/${measurementName}`, {
          method: "DELETE",
        })
          .then(() => {
            mutatePositionFiles(positionFiles, true);
          })
          .catch((response) => {
            setDeleteErrorResponse(response);
            console.error(response);
          });
      }}
      alertHeader={`Delete all images for ${measurementName}`}
      alertBody="This will delete all images, annotations and predictions. Are you sure?"
    />
  );
};

const AlertForbidden = ({ response }) => {
  const [errorMessage, setErrorMessage] = useState("");
  response
    .clone()
    .json()
    .then((json) => setErrorMessage(json?.detail));
  return (
    <Alert status="error" borderRadius="sm" marginTop="5px" width="300px">
      <AlertIcon />
      <AlertTitle mr={2}>{response.status}</AlertTitle>
      <AlertDescription>{errorMessage}</AlertDescription>
    </Alert>
  );
};
