import stateLogger from "./logger";

const actionHandler = (state, { type, key, payload }) => {
  switch (type) {
    case "set": {
      return {
        ...state,
        [key]: payload,
      };
    }

    // Partial update of object that is
    // set to a key
    case "patch": {
      return {
        ...state,
        [key]: {
          ...state[key],
          ...payload,
        },
      };
    }

    // Append item to state array
    case "append": {
      return {
        ...state,
        [key]: [...state[key], payload],
      };
    }

    default: {
      return state;
    }
  }
};

export const reducer = (config) => (state, action) => {
  const nextState = actionHandler(state, action);
  if (config?.logging.level === "DEBUG") stateLogger(state, action, nextState);
  return nextState;
};
