import noAction from "../styles/markers/no_action.svg";
import immediateInspection from "../styles/markers/immediate_inspection.svg";
import inspectionWithinThreeWeeks from "../styles/markers/inspection_within_three_weeks.svg";
import inspectionWithinOneYear from "../styles/markers/inspection_within_one_year.svg";
import noInspection from "../styles/markers/no_inspection.svg";

export const IMMEDIATE_INSPECTION = "immediate_inspection";
export const INSPECTION_WITHIN_THREE_WEEKS = "inspection_within_three_weeks";
export const INSPECTION_WITHIN_ONE_YEAR = "inspection_within_one_year";
export const NO_INSPECTION = "no_inspection";

export const actionNames = {
  [IMMEDIATE_INSPECTION]: "1. Immediate inspection",
  [INSPECTION_WITHIN_THREE_WEEKS]: "2. Inspection within three weeks",
  [INSPECTION_WITHIN_ONE_YEAR]: "3. Inspection within one year",
  [NO_INSPECTION]: "4. No inspection",
};

export const actionMarkers = {
  null: noAction,
  [IMMEDIATE_INSPECTION]: immediateInspection,
  [INSPECTION_WITHIN_THREE_WEEKS]: inspectionWithinThreeWeeks,
  [INSPECTION_WITHIN_ONE_YEAR]: inspectionWithinOneYear,
  [NO_INSPECTION]: noInspection,
};
