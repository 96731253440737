import { Flex, Heading, Text } from "@chakra-ui/react";

export const NotFound404 = () => {
  return (
    <Flex alignItems="center" direction="column" marginTop="20">
      <Heading fontSize="8xl" fontWeight="black" color="yellow.500">
        404
      </Heading>
      <Text fontSize="3xl" fontWeight="black" color="gray.500" marginTop="10">
        This page does not exist :(
      </Text>
    </Flex>
  );
};
