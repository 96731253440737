import {
  Spinner,
  Text,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";
import useSearch from "./useSearch.js";
import { Prediction } from "./Prediction.jsx";

export default function SearchResults() {
  const location = useLocation();
  const queryParams = Object.fromEntries(new URLSearchParams(location.search));

  const images = useSearch(queryParams);

  if (!images.isSearching) {
    return null;
  }

  if (images.error) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription mr={2}>
          Unable to retrieve search results!
        </AlertDescription>
      </Alert>
    );
  }

  if (images.isLoading) {
    return (
      <div style={{ height: "200px", marginTop: "100px" }}>
        <Spinner />
      </div>
    );
  }

  if (images.data.length === 0) {
    return (
      <div style={{ height: "200px", marginTop: "100px" }}>
        <Text fontSize="lg">No images found</Text>
      </div>
    );
  }

  return (
    <>
      {images.data.map((image) => (
        <Prediction key={image.id} image={image} />
      ))}
    </>
  );
}
