import { useKeycloak } from "@react-keycloak/web";
import {
  Avatar,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Box,
  Divider,
} from "@chakra-ui/react";
import { SignInButton } from "./SignInButton";
import { useHistory } from "react-router-dom";
import { onEvent } from "../../utils/onEvent";

export const NavigationMenu = () => {
  const { keycloak } = useKeycloak();
  const { push } = useHistory();

  if (keycloak?.authenticated) {
    return (
      <Box marginLeft="auto" paddingLeft="10">
        <Menu>
          <MenuButton marginLeft="auto">
            <Avatar size="md" name={keycloak?.tokenParsed?.name} />
          </MenuButton>
          <Portal>
            <MenuList zIndex="dropdown">
              <MenuItem onClick={onEvent(push, "/user/statistics")}>
                Statistics
              </MenuItem>
              <Divider />
              <MenuItem onClick={keycloak?.logout}>Sign Out</MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Box>
    );
  }

  // NOTE: This seems to never be used...
  return <SignInButton />;
};
