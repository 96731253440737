import { Flex } from "@chakra-ui/react";

export const ImageMessageWrapper = ({ children }) => (
  <Flex
    width="full"
    height="full"
    backgroundColor="gray.100"
    fontSize="lg"
    padding="5"
    justifyContent="center"
    alignItems="center"
  >
    {children}
  </Flex>
);
