import { createContext, useContext, useEffect, useState } from "react";

const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    if (config === null) {
      fetch("/config.json")
        .then((response) => response.json())
        .then(setConfig)
        .catch((_) => {
          throw new Error("Failed to read config.json");
        });
    }
  }, [config]);

  if (config === null) {
    return null;
  }

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export const useConfig = () => useContext(ConfigContext);
