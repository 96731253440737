import { Flex } from "@chakra-ui/react";

export const DamageWrapper = ({ children }) => {
  return (
    <Flex
      direction="column"
      border="1px"
      borderColor="gray.300"
      rounded="md"
      marginBottom="5"
    >
      {children}
    </Flex>
  );
};
