import { useHistory, useLocation } from "react-router";
import { ButtonGroup, Button } from "@chakra-ui/react";
import { FaList, FaMapMarkedAlt } from "react-icons/fa";

export const ViewTypeButtons = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <ButtonGroup size="sm" isAttached variant="outline">
      <Button
        leftIcon={<FaList />}
        mr="-px"
        color={
          ["/", "/damages"].includes(location.pathname)
            ? "green.500"
            : "gray.400"
        }
        borderColor={
          ["/", "/damages"].includes(location.pathname)
            ? "green.500"
            : "gray.400"
        }
        onClick={() => {
          history.push("/damages");
        }}
      >
        List
      </Button>
      <Button
        leftIcon={<FaMapMarkedAlt />}
        color={
          ["/damage-map"].includes(location.pathname) ? "green.500" : "gray.400"
        }
        borderColor={
          ["/damage-map"].includes(location.pathname) ? "green.500" : "gray.400"
        }
        borderLeftColor={"green.500"}
        onClick={() => {
          history.push("/damage-map");
        }}
      >
        Map
      </Button>
    </ButtonGroup>
  );
};
