export const onEvent =
  (func, ...args) =>
  (_) =>
    func(...args);

export const withEvent =
  (func, ...args) =>
  (event) =>
    func(...args, event);

export const withTargetValue =
  (func, ...args) =>
  ({ target: { value } }) =>
    func(...args, value);
