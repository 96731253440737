export const DAMAGE = "damage";
export const SOFT_WIRE_DAMAGE = "soft_wire_damage";
export const JOINT = "joint";
export const TARGETS = {
  [DAMAGE]: ["serious_damage", "broken_strand", "tape_joint", "faulty_joint"],
  [SOFT_WIRE_DAMAGE]: ["broken_soft_wire"],
  [JOINT]: ["press_joint", "twist_joint"],
};

export const BRIEFLY_CHECKED_NOT_DAMAGE = "briefly_checked_not_damage";
export const BRIEFLY_CHECKED_NOT_JOINT = "briefly_checked_not_joint";

export const DAMAGE_KEYBOARD_MAP = {
  1: "broken_strand",
  2: "tape_joint",
  3: "broken_soft_wire",
  4: "faulty_joint",
  5: "verified_not_damage",
  6: "unclear_damage",
  7: "false_damage",
  8: "major_bend",
  9: "minor_bend",
  0: "possible_damage",
  b: "bent_joint",
};

export const SOFT_WIRE_DAMAGE_KEYBOARD_MAP = DAMAGE_KEYBOARD_MAP;

export const JOINT_KEYBOARD_MAP = {
  1: "twist_joint",
  2: "press_joint",
  3: "heat_protection",
  4: "verified_not_joint",
  5: "unclear_joint",
  6: "false_joint",
};

export const DAMAGE_ANNOTATION_TYPES = [
  ...Object.values(DAMAGE_KEYBOARD_MAP),
  BRIEFLY_CHECKED_NOT_DAMAGE,
];
export const SOFT_WIRE_DAMAGE_ANNOTATION_TYPES = DAMAGE_ANNOTATION_TYPES;
export const JOINT_ANNOTATION_TYPES = [
  ...Object.values(JOINT_KEYBOARD_MAP),
  BRIEFLY_CHECKED_NOT_JOINT,
];

export const ANNOTATION_TYPES = {
  [DAMAGE]: DAMAGE_ANNOTATION_TYPES,
  [SOFT_WIRE_DAMAGE]: SOFT_WIRE_DAMAGE_ANNOTATION_TYPES,
  [JOINT]: JOINT_ANNOTATION_TYPES,
};
