import { Box } from "@chakra-ui/react";

export const HoverLayer = ({ isVisible, children, ...props }) => {
  if (isVisible) {
    return (
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        backgroundColor="blackAlpha.600"
        padding="5"
        color="white"
        fontWeight="black"
        {...props}
      >
        {children}
      </Box>
    );
  }
  return null;
};
