import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import VectorLayer from "ol/layer/Vector";
import "ol/ol.css";
import { fromLonLat } from "ol/proj";
import VectorSource from "ol/source/Vector";
import { Icon, Style } from "ol/style";
import pin from "../../styles/markers/pin.svg";
import { actionMarkers } from "../../utils/actionConstants.js";
import { getMapView } from "./getMapView";

export const reloadVectorLayer = ({
  map,
  inspectionImages,
  location,
  setDamageIndex,
  history,
}) => {
  map.current.getLayers().forEach((layer) => {
    if (layer.get("name") && layer.get("name") === "marker_layer") {
      map.current.removeLayer(layer);
    }
  });

  let inspectionMarkers = inspectionImages.data.map(
    (image, index) =>
      new Feature({
        type: "icon",
        geometry: new Point(fromLonLat(image.closest_position.coordinates)),
        index: index,
      })
  );

  const { coordinates, setPin } = getMapView(
    location.search,
    inspectionImages.data
  );

  if (setPin) {
    inspectionMarkers = [
      ...inspectionMarkers,
      new Feature({
        type: "pin",
        geometry: new Point(fromLonLat(coordinates)),
      }),
    ];
  }

  const vectorLayer = new VectorLayer({
    source: new VectorSource({
      features: inspectionMarkers,
    }),
    name: "marker_layer",
    style: (feature) => {
      const image = inspectionImages.data[feature.values_.index];
      const markerType = feature.get("type");
      if (markerType === "pin") {
        return new Style({
          image: new Icon({
            anchor: [0.5, 1],
            src: pin,
            scale: 1,
            opacity: 1,
          }),
        });
      } else {
        return new Style({
          image: new Icon({
            anchor: [0.5, 1],
            src: image.inspection.action_type
              ? actionMarkers[image.inspection.action_type]
              : actionMarkers[null],
            scale: 0.5,
            opacity: 1,
          }),
        });
      }
    },
  });

  map.current.addLayer(vectorLayer);

  map.current.on("click", function (event) {
    vectorLayer.getFeatures(event.pixel).then(function (features) {
      const feature = features.length ? features[0] : undefined;
      if (feature && feature.get("type") === "icon") {
        setDamageIndex(feature.values_.index);
        history.replace(
          `/damage-map?inspection=${
            inspectionImages.data[feature.values_.index].id
          }`
        );
      } else {
        setDamageIndex(undefined);
      }
    });
  });

  map.current.on("pointermove", function (event) {
    vectorLayer.getFeatures(event.pixel).then(function (features) {
      map.current.getViewport().style.cursor =
        features.length > 0 ? "pointer" : "";
    });
  });
};
