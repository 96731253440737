import { Flex, Tag, TagLabel } from "@chakra-ui/react";

const ImageTag = ({ id, type }) => (
  <Tag
    size="sm"
    key={id}
    borderRadius="full"
    variant="solid"
    color="gray.800"
    backgroundColor="orange.100"
    margin="1"
    fontWeight="600"
  >
    <TagLabel>{type}</TagLabel>
  </Tag>
);

export const ImageTags = ({ image, ...props }) => (
  <Flex
    width="full"
    position="absolute"
    bottom="2"
    left="1"
    wrap="wrap-reverse"
    {...props}
  >
    {image?.data?.annotations?.map(ImageTag)}
  </Flex>
);
