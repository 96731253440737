import {
  CheckCircleIcon,
  InfoIcon,
  RepeatIcon,
  SpinnerIcon,
  WarningIcon,
  QuestionIcon,
  ViewOffIcon,
} from "@chakra-ui/icons";
import {
  UNPROCESSED,
  QUEUED,
  PROCESSING,
  PROCESSED,
  ERROR,
  MISSING,
  IGNORED,
} from "../../utils/positionFileStatuses";

export const StatusIcon = ({ status, ...props }) => {
  switch (status) {
    case UNPROCESSED: {
      return <InfoIcon color="orange.400" {...props} />;
    }
    case QUEUED: {
      return <RepeatIcon color="blue.400" {...props} />;
    }
    case PROCESSING: {
      return <SpinnerIcon color="blue.500" {...props} />;
    }
    case PROCESSED: {
      return <CheckCircleIcon color="green.500" {...props} />;
    }
    case ERROR: {
      return <WarningIcon color="red.600" {...props} />;
    }
    case MISSING: {
      return <QuestionIcon color="gray.400" {...props} />;
    }
    case IGNORED: {
      return <ViewOffIcon color="gray.400" {...props} />;
    }
    default: {
      return null;
    }
  }
};
