import useBackend from "./useBackend";

const useUser = (userId, options) => {
  const user = useBackend(userId ? `/users/${userId}` : null);
  return {
    ...user,
  };
};

export default useUser;
