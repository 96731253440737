import { EditIcon } from "@chakra-ui/icons";
import { LinkButton } from "../../LinkButton";

export const AnnotationLink = ({ image }) => {
  return (
    <LinkButton
      marginLeft="2"
      href={`/images/${image.data.measurement_name}/${image.data.frame_index}/${image.data.camera_index}`}
    >
      <EditIcon marginRight="2" /> Annotation
    </LinkButton>
  );
};
