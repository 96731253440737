import useBackend from "../../backend/hooks/useBackend";

const useSearch = ({
  trackSection,
  kilometer,
  meter,
  trackLane,
  latitude,
  longitude,
}) => {
  const isSearching =
    (trackSection && kilometer && meter) || (latitude && longitude);

  return {
    isSearching,
    ...useBackend(isSearching ? "/images/closest-per-measurement" : null, {
      query: {
        trackSection,
        kilometer,
        meter,
        trackLane,
        latitude,
        longitude,
      },
    }),
  };
};

export default useSearch;
