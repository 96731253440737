import { Flex, Text } from "@chakra-ui/react";
import { isDefined } from "@nextml/lodestar";
import timeSince from "../../utils/timeSince";

const DamageFooterWrapper = ({ children }) => {
  return (
    <Flex
      direction="row"
      align="center"
      width="full"
      backgroundColor="gray.50"
      paddingTop="2"
      paddingBottom="2"
      paddingLeft="3"
      paddingRight="3"
      borderBottomLeftRadius="md"
      borderBottomRightRadius="md"
    >
      {children}
    </Flex>
  );
};

export const DamageFooter = ({ children, inspection }) => {
  return (
    <DamageFooterWrapper>
      {isDefined(inspection?.created) && (
        <Text fontSize="xs" color="gray.500">
          Marked for review {timeSince(new Date(inspection.created + "Z"))}
        </Text>
      )}
      <Flex marginLeft="auto">{children}</Flex>
    </DamageFooterWrapper>
  );
};
