import { ErrorMessage } from "./ErrorMessage";
import { ImageMessageWrapper } from "./ImageMessageWrapper";

export const Image = ({ isLoading, error, ...props }) => {
  if (isLoading) {
    return <ImageMessageWrapper>Loading...</ImageMessageWrapper>;
  }

  if (error) {
    return <ImageMessageWrapper>{ErrorMessage(error)}</ImageMessageWrapper>;
  }

  return <img alt="" {...props} />;
};
