import { SearchIcon } from "@chakra-ui/icons";
import { UrlStateInput } from "./UrlStateInput";

export const CommentFilter = ({ urlStateKey }) => (
  <UrlStateInput
    urlStateKey={urlStateKey}
    placeholder="Comment filter..."
    marginBottom="5"
    size="sm"
    variant="filled"
    borderRadius="md"
    icon={<SearchIcon />}
  />
);
