import { useState } from "react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Td, Text, Tr, Button } from "@chakra-ui/react";
import { toDisplayDate } from "../../utils/toDisplayDate";
import { ActionsCell } from "./ActionsCell";
import { StatusIcon } from "./StatusIcon";
import { defer } from "@nextml/lodestar";
import { ErrorModal } from "../../components/ErrorModal";

const StatusCell = ({ status, errorMessage }) => {
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);

  if (status === "error") {
    return (
      <>
        <ErrorModal
          title="Position file error"
          isOpen={errorModalIsOpen}
          onClose={defer(setErrorModalIsOpen, false)}
          description={errorMessage}
        />
        <Button variant={"link"} onClick={defer(setErrorModalIsOpen, true)}>
          <StatusIcon status={status} />
          &nbsp;
          <Text
            display="inline"
            textTransform="uppercase"
            fontWeight="bold"
            color="blue.400"
            textDecoration="underline"
            fontSize="0.8em"
          >
            {status}
          </Text>
        </Button>
      </>
    );
  }

  return (
    <>
      <StatusIcon status={status} />
      &nbsp;
      <Text
        display="inline"
        textTransform="uppercase"
        fontWeight="bold"
        color="gray.600"
        fontSize="0.8em"
      >
        {status}
      </Text>
    </>
  );
};

export const PositionFileRow = ({
  measurement_name,
  hdr,
  kmm2,
  status,
  created,
  error_message,
}) => {
  return (
    <Tr key={measurement_name}>
      <Td>{measurement_name}</Td>
      <Td textAlign="center">
        {kmm2 === true ? <CheckCircleIcon color={"green.500"} /> : null}
      </Td>
      <Td textAlign="center">
        {/* {hdr !== undefined && hdr !== null ? ( */}
        {hdr === true ? <CheckCircleIcon color={"green.500"} /> : null}
      </Td>

      <Td>
        <StatusCell status={status} errorMessage={error_message} />
      </Td>

      <Td>
        {created !== undefined && created !== null
          ? toDisplayDate(created)
          : ""}
      </Td>
      <ActionsCell measurementName={measurement_name}></ActionsCell>
    </Tr>
  );
};
