import { useLocation, useHistory } from "react-router-dom";

const deserializeUrlParameter = (parameterString, defaultValue) => {
  const [key, value] = parameterString.split("=");

  return {
    [key]: Array.isArray(defaultValue)
      ? value.split(",").filter((string) => string !== "")
      : value,
  };
};

const serializeUrlParameter = (nextState, defaultValue) => (key) => {
  if (Array.isArray(defaultValue)) {
    const urlArray = nextState[key].join(",");
    return `${key}=${urlArray}`;
  }

  return `${key}=${nextState[key]}`;
};

const empty = (value) => {
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  return [undefined, ""].includes(value);
};

const removeEmptyValues = (state) => {
  return Object.keys(state).reduce((nextState, key) => {
    if (empty(state[key])) {
      return nextState;
    }
    return { ...nextState, [key]: state[key] };
  }, {});
};

export const useUrlState = (key, defaultValue = "") => {
  const location = useLocation();
  const history = useHistory();

  const urlState = location.search
    .slice(1)
    .split("&")
    .filter((string) => string !== "")
    .reduce((nextState, parameterString) => {
      return {
        ...nextState,
        ...deserializeUrlParameter(parameterString, defaultValue),
      };
    }, {});

  const set = (value) => {
    const nextState = removeEmptyValues({
      ...urlState,
      [key]: value,
    });

    const nextUrl = `?${Object.keys(nextState)
      .map(serializeUrlParameter(nextState, defaultValue))
      .join("&")}`;

    if (["?", "?&"].includes(nextUrl)) {
      history.replace(location.pathname);
    } else {
      history.replace(nextUrl);
    }
  };

  return {
    data: urlState[key] || defaultValue,
    set,
  };
};
