import React from "react";
import { Tag, TagLabel, Flex, Tooltip } from "@chakra-ui/react";
import useImage from "../../backend/hooks/useImage";
import { useModels } from "./useModels";
import { copyToClipboard } from "../../utils/copyToClipboard";
import { onEvent } from "../../utils/onEvent";

const PredictionTag =
  (modelsMap) =>
  ({ target, probability, model_id }) => {
    const modelName = modelsMap[model_id]?.name;
    return (
      <Tag
        size="sm"
        key={target + probability}
        borderRadius="full"
        variant="solid"
        color="white"
        backgroundColor="red.600"
        margin="1"
        fontWeight="600"
        onClick={onEvent(copyToClipboard, modelName)}
      >
        <Tooltip label={modelName}>
          <TagLabel>
            {target} {(probability * 100).toFixed(1)}%
          </TagLabel>
        </Tooltip>
      </Tag>
    );
  };

export const PredictionsList = ({ imageId }) => {
  const image = useImage(imageId);

  const models = useModels();
  const modelsMap = Object.fromEntries(
    (models.data ?? []).map((m) => [m.id, m])
  );

  if (
    !image.isLoading &&
    (!image.data?.predictions || image.data.predictions.length === 0)
  ) {
    return null;
  }

  return (
    <Flex alignItems="center" paddingBottom="2" paddingTop="2">
      {image?.data?.predictions
        .sort((predictionA, predictionB) => {
          return predictionA.created > predictionB.created ? -1 : 1;
        })
        .map(PredictionTag(modelsMap))}
    </Flex>
  );
};
