import { DeleteIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { useState, useRef } from "react";
import { onEvent } from "../utils/onEvent";

export const DeleteButton = ({ title, onClick, alertHeader, alertBody }) => {
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();

  return (
    <>
      <IconButton
        onClick={onEvent(setIsOpen, true)}
        icon={<DeleteIcon />}
        colorScheme="red"
        size="xs"
        cursor="pointer"
        marginLeft="2"
        title={title}
      />
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onEvent(setIsOpen, false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {alertHeader}
            </AlertDialogHeader>

            <AlertDialogBody>{alertBody}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onEvent(setIsOpen, false)}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onClick();
                  setIsOpen(false);
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
