import useImages from "./useImages";

const useInspections = (query, options) => {
  return useImages(
    query && {
      inspection: true,
      ...query,
    },
    options
  );
};

export default useInspections;
