import {
  HStack,
  Tag,
  TagLabel,
  TagCloseButton,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import useImage from "../../backend/hooks/useImage";
import useUser from "../../backend/hooks/useUser";

export const AnnotationList = ({ imageId }) => {
  const image = useImage(imageId);

  if (image.isLoading) {
    return null;
  }

  return (
    <Flex alignItems="center" paddingBottom="2" paddingTop="2">
      <HStack flex="1" flexWrap="wrap">
        {!image.data?.annotations || image.data?.annotations.length === 0 ? (
          <Tag size="sm" style={{ visibility: "hidden" }}>
            <TagLabel>hidden</TagLabel>
          </Tag>
        ) : null}
        {image.data?.annotations?.map((annotation) => (
          <AnnotationTag
            image={image}
            annotation={annotation}
            key={annotation.id}
          />
        ))}
      </HStack>
    </Flex>
  );
};

const AnnotationTag = ({ image, annotation }) => {
  const user = useUser(annotation.user_id);
  return (
    <Tag
      key={`${annotation.id}`}
      size="sm"
      borderRadius="full"
      variant="solid"
      color="gray.800"
      backgroundColor="orange.100"
      margin="1"
      fontWeight="600"
    >
      <Tooltip label={`${user?.data?.first_name} ${user?.data?.last_name}`}>
        <TagLabel>{annotation.type}</TagLabel>
      </Tooltip>
      <TagCloseButton
        onClick={() => {
          image.deleteAnnotation(annotation.id);
        }}
      />
    </Tag>
  );
};
