import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { StatusIcon } from "../views/PositionUpload/StatusIcon";

export const ErrorModal = ({ title, description, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent padding="10">
        <ModalHeader justifyContent="center" display="flex" alignItems="center">
          <StatusIcon status="error" marginRight="2" /> {title}
        </ModalHeader>

        <ModalBody textAlign="center">{description}</ModalBody>

        <ModalFooter justifyContent="center">
          <Button size="sm" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
