import { useKeycloak } from "@react-keycloak/web";
import { useConfig } from "../../state/ConfigProvider";

const checkResponseStatus = (response) => {
  if (response.status >= 400) {
    throw response;
  }
  return response;
};

export const useAuthorizedFetcher = () => {
  const { keycloak } = useKeycloak();
  const config = useConfig();

  if (keycloak?.token) {
    return (endpoint, requestOptions = {}) =>
      fetch(`${config.api.endpoint}${endpoint}`, {
        ...requestOptions,
        method: requestOptions?.method ? requestOptions.method : "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${keycloak.token}`,
          ...requestOptions?.headers,
        },
        body: requestOptions?.body
          ? JSON.stringify(requestOptions.body)
          : undefined,
      })
        .then(checkResponseStatus)
        .then((response) => {
          if (response.headers.get("content-type") === "application/json") {
            return response.json();
          } else {
            return response.blob();
          }
        });
  } else {
    return null;
  }
};
