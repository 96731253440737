import { Button, Spinner } from "@chakra-ui/react";
import { DamageFooter } from "../../components/Damage/DamageFooter.jsx";
import { useState } from "react";
import { useAuthorizedFetcher } from "../../backend/hooks/useAuthorizedFetcher.js";
import { ErrorModal } from "../../components/ErrorModal";
import { downloadBase64Image } from "../../utils/downloadBase64Image.js";

export const PredictionFooter = ({ image }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [downloadErrorText, setDownloadErrorText] = useState(null);
  const authorizedFetcher = useAuthorizedFetcher();

  const download = (cameraIndex) => {
    const queryParameters = new URLSearchParams({
      measurement_name: image.measurement_name,
      from_frame_index: image.frame_index - 50,
      to_frame_index: image.frame_index + 50,
      camera_index: cameraIndex,
      realistic: true,
    });
    setIsDownloading(true);
    authorizedFetcher(
      "/images/image-data/stitched?" + queryParameters.toString()
    )
      .then((base64String) => {
        downloadBase64Image(
          base64String,
          `${image.measurement_name}_${image.frame_index}_${cameraIndex}.png`
        );
        setDownloadErrorText(null);
        setIsDownloading(false);
      })
      .catch((error) => {
        setDownloadErrorText(`${error.status}: ${error.statusText}`);
        setErrorModalIsOpen(true);
        setIsDownloading(false);
      });
  };

  return (
    <DamageFooter>
      <Button
        onClick={() => {
          download(211);
          download(212);
        }}
        size="xs"
        fontSize="xs"
        borderRadius="md"
        border="1px solid"
        borderColor="gray.200"
        display="flex"
        alignItems="center"
        justifyContent="center"
        textDecoration="none"
        disabled={isDownloading}
      >
        Download
        {isDownloading && <Spinner size="sm" marginLeft="5px" color="blue" />}
      </Button>
      <ErrorModal
        title="Download error"
        isOpen={errorModalIsOpen}
        onClose={() => {
          setErrorModalIsOpen(false);
          setDownloadErrorText(null);
        }}
        description={downloadErrorText}
      />
    </DamageFooter>
  );
};
