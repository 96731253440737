export const ErrorMessage = (error) => {
  switch (error?.status) {
    case 404: {
      return "404 Image not found";
    }

    default: {
      return "Error";
    }
  }
};
