import { Table, Tbody, Td, Th, Tr, VStack } from "@chakra-ui/react";
import { NearbyInspections } from "../../components/NearbyInspections.jsx";
import { DAMAGE, TARGETS } from "../Annotate/annotationTypes.js";
import { DamageWrapper } from "../../components/Damage/DamageWrapper.jsx";
import { DamageHeader } from "../../components/Damage/DamageHeader.jsx";
import { DamageContent } from "../../components/Damage/DamageContent.jsx";
import { InspectionImage } from "../../components/InspectionImage";

import { PredictionFooter } from "./PredictionFooter.jsx";
import { take } from "../../utils/take.js";

const sortPredictionList = (predictions) =>
  predictions
    .filter((prediction) => TARGETS[DAMAGE].includes(prediction.target))
    .sort(
      (predictionA, predictionB) =>
        predictionA.probability - predictionB.probability
    );

export const Prediction = ({ image }) => {
  if (!image) {
    return null;
  }

  const predictions = sortPredictionList(image.predictions);

  return (
    <DamageWrapper>
      <DamageHeader image={image} />
      <DamageContent>
        {/* TODO: solve the InspectionImage parameter in a nicer way*/}
        <InspectionImage image={{ data: image }} />
        <VStack width="100%" margin="10px" marginTop="6px">
          <Table size="sm">
            <Tbody>
              <Tr>
                <Th>Target</Th>
                <Th>Probability</Th>
              </Tr>
              {take(3, predictions).map((prediction, index) => (
                <Tr key={`prediction::${index}`}>
                  <Td isNumeric>{prediction.target}</Td>
                  <Td isNumeric>
                    {(prediction.probability * 100).toFixed(1)}%
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <NearbyInspections
            status={"info"}
            filter={(otherImage) =>
              otherImage.measurement_name === image.measurement_name
            }
            measurementName={image.measurement_name}
            frameIndex={image.frame_index}
            official={true}
          />
        </VStack>
      </DamageContent>
      <PredictionFooter image={image} />
    </DamageWrapper>
  );
};
