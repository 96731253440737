import useBackend from "./useBackend";
import useImageWithId from "./useImage";

const camelToUnderscore = (key) => {
  var result = key.replace(/([A-Z])/g, " $1");
  return result.split(" ").join("_").toLowerCase();
};

const useImages = (query, options) => {
  const images = useBackend(query ? "/images" : null, {
    query,
    options,
  });

  const useImage = (imageQuery) => {
    const data = imageQuery
      ? images.data?.find((image) =>
          Object.keys(imageQuery).every(
            (key) => imageQuery[key] === image[camelToUnderscore(key)]
          )
        )
      : images.data?.[0];

    const image = useImageWithId(data?.id, { fallbackData: data });

    if (!images.isLoading && !data) {
      return { data: undefined, error: "Image not found", isLoading: false };
    } else {
      return image;
    }
  };

  return {
    ...images,
    useImage,
  };
};

export default useImages;
