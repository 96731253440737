import {
  Container,
  Box,
  Center,
  Spinner,
  Flex,
  Spacer,
  Heading,
  VStack,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import TileLayer from "ol/layer/Tile";
import Map from "ol/Map";
import "ol/ol.css";
import { fromLonLat } from "ol/proj";
import XYZ from "ol/source/XYZ";
import View from "ol/View";
import { useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Damage } from "../Damages/Damage.jsx";
import { getMapView } from "./getMapView";
import { reloadVectorLayer } from "./reloadVectorLayer.js";
import { ViewTypeButtons } from "../Damages/ViewTypeButtons.jsx";
import useInspections from "../../backend/hooks/useInspections.js";

export const DamageMap = () => {
  const [damageIndex, setDamageIndex] = useState();
  const location = useLocation();
  const history = useHistory();
  const map = useRef();

  const inspectionImages = useInspections({ official: true });
  const selectedInspectionImage = inspectionImages.data?.[damageIndex];

  useEffect(() => {
    if (inspectionImages.data) {
      if (!map.current) {
        const { inspection, coordinates, zoom } = getMapView(
          location.search,
          inspectionImages.data
        );

        setDamageIndex(inspection);

        map.current = new Map({
          target: "map",
          layers: [
            new TileLayer({
              source: new XYZ({
                url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
              }),
            }),
          ],
          view: new View({
            center: fromLonLat(coordinates),
            zoom: zoom,
          }),
        });
      }

      reloadVectorLayer({
        map,
        inspectionImages,
        location,
        setDamageIndex,
        history,
      });
    }
  }, [history, inspectionImages, location, location.search]);

  if (inspectionImages.isLoading) {
    return (
      <Container maxW="container.md">
        <Center height="100px">
          <Spinner />
        </Center>
      </Container>
    );
  }

  const inspectionsWithoutAction = inspectionImages.data.filter(
    (inspection) => !inspection.action_type
  );

  return (
    <Container maxW="container.lg">
      <VStack>
        {inspectionsWithoutAction.length >= 1 && (
          <Alert status="warning" margin="0 2px 0px 2px" variant="left-accent">
            <AlertIcon />
            There are {inspectionsWithoutAction.length} damages without an
            action.
          </Alert>
        )}
        <Flex width="100%" marginTop="20px">
          <Spacer />
          <Heading size="lg">Damages</Heading>
          <Spacer>
            <Flex>
              <Spacer />
              <ViewTypeButtons />
            </Flex>
          </Spacer>
        </Flex>
        <Box width="100%" height="500px">
          <div id="map" style={{ width: "100%", height: "100%" }}></div>
        </Box>
        {damageIndex !== undefined && (
          <Damage
            width="100%"
            index={0}
            key={damageIndex}
            initialImage={selectedInspectionImage}
            onChange={(newInspection) => {
              inspectionImages.data[damageIndex] = newInspection;
              reloadVectorLayer({
                map,
                inspectionImages,
                location,
                setDamageIndex,
                history,
              });
              inspectionImages.mutate(inspectionImages.data, false);
            }}
            onDelete={() => {
              // TODO
            }}
          />
        )}
      </VStack>
    </Container>
  );
};
