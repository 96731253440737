import { ArrowBackIcon } from "@chakra-ui/icons";
import { Kbd, Text, Flex } from "@chakra-ui/react";
import useImages from "../../backend/hooks/useImages";

export const Key = ({ k, children, isDisabled, ...props }) => (
  <Flex
    direction="column"
    alignItems="center"
    color={"#dddddd"}
    flex="1"
    {...props}
  >
    <Kbd color={isDisabled ? "#dddddd" : "gray.700"}>{k} </Kbd>

    <Text
      marginTop="2"
      marginLeft="2"
      fontSize="xs"
      textTransform="uppercase"
      textAlign="center"
      fontWeight="semibold"
    >
      {children}
    </Text>
  </Flex>
);

export const CameraKeyBindings = ({
  measurementName,
  frameIndex,
  cameraIndex,
}) => {
  const images = useImages({ measurementName, requiresAnnotation: true });
  const image = images.useImage({
    frame_index: frameIndex,
    camera_index: cameraIndex,
  });
  const leftImage = images.useImage({
    frame_index: frameIndex,
    camera_index: cameraIndex - 1,
  });
  const rightImage = images.useImage({
    frame_index: frameIndex,
    camera_index: cameraIndex + 1,
  });

  return (
    <Flex width="full">
      <Key k="q">Previous by probability</Key>
      <Key k="w">Next frame</Key>
      <Key k="e">Next by probability</Key>
      <Key k="a" isDisabled={leftImage.data === undefined}>
        Previous camera
      </Key>
      <Key k="s">Previous frame</Key>
      <Key k="d" isDisabled={rightImage.data === undefined}>
        Next camera
      </Key>

      <Key k="r">Next unannotated</Key>

      <span />

      <Key
        k={<ArrowBackIcon />}
        isDisabled={
          image.data?.annotations && image.data.annotations.length >= 1
        }
      >
        Remove last annotation
      </Key>
    </Flex>
  );
};
