import { useState } from "react";

export const useInspectionImageNavigate = (image) => {
  const [nextFrameIndex, setNextFrameIndex] = useState(
    image?.data?.frame_index
  );
  const [nextCameraIndex, setNextCameraIndex] = useState(
    image?.data?.camera_index
  );

  const onKeyDown = (event) => {
    event.preventDefault();

    switch (event.key) {
      case "ArrowRight":
      case "d": {
        setNextCameraIndex(212);
        break;
      }

      case "ArrowLeft":
      case "a": {
        setNextCameraIndex(211);
        break;
      }

      case "ArrowUp":
      case "w": {
        setNextFrameIndex(nextFrameIndex + 1);
        break;
      }

      case "ArrowDown":
      case "s": {
        setNextFrameIndex(nextFrameIndex - 1);
        break;
      }

      default: {
        return;
      }
    }
  };

  return {
    nextFrameIndex,
    setNextFrameIndex,
    nextCameraIndex,
    setNextCameraIndex,
    onKeyDown,
  };
};
