import { Center, Container, Link } from "@chakra-ui/react";
import { useKeycloak } from "@react-keycloak/web";

export const ViewAuthentication = ({ children }) => {
  const { keycloak } = useKeycloak();

  if (keycloak === undefined) {
    return <div>Loading...</div>;
  }

  if (keycloak.authenticated) {
    return children;
  }

  return (
    <Container>
      <Center height="100px">
        You need to&nbsp;
        <Link onClick={keycloak.login} color="teal.500">
          login
        </Link>
        &nbsp;to see measurements and annotate.
      </Center>
    </Container>
  );
};
