import { Grid, GridItem, Flex, FormLabel } from "@chakra-ui/react";
import { FilterWrapper } from "./FilterWrapper";
import { UrlStateInput } from "./UrlStateInput";

const Label = ({ children }) => (
  <GridItem>
    <FormLabel fontSize="sm" fontWeight="bold">
      {children}
    </FormLabel>
  </GridItem>
);

export const SectionFilters = () => (
  <FilterWrapper>
    <Flex direction="column">
      <Grid templateRows="repeat(4, 1fr)" templateColumns="1fr 2fr" gap={2}>
        <Label>Track&nbsp;Section</Label>
        <UrlStateInput urlStateKey="trackSection" size="sm" borderRadius="sm" />

        <Label>Kilometer</Label>
        <UrlStateInput urlStateKey="kilometer" size="sm" borderRadius="sm" />

        <Label>Meter</Label>
        <UrlStateInput urlStateKey="meter" size="sm" borderRadius="sm" />

        <Label>Track&nbsp;Lane</Label>
        <UrlStateInput urlStateKey="trackLane" size="sm" borderRadius="sm" />
      </Grid>
    </Flex>
  </FilterWrapper>
);
